
// Navigation

.navbar {
  background: $brand-primary;
  margin: 0;
  border: 0;
  border-radius: 0;
  min-height: 64px;

  .navbar-brand {
    padding: 15px;
    height: auto;

    img {
      height: 44px;
      width: auto;
      margin-right: 30px;
    }
  }

  .navbar-toggle {
    margin: 0;
    border: none;
    border-radius: 0;
    top: 14px;
    right: 15px;
    font-size: 20px;
    color: white;

    &:focus {
      background: transparent;
    }
  }

  .navbar-collapse {
    border: none;
    box-shadow: none;
    background: darken($brand-primary, 5%);
    padding: 10px 15px;
  }

  .navbar-nav {
    margin: 0 -15px;

    li {
      a {
        font-family: $font-family-serif;
        color: white;
        text-transform: uppercase;
        font-size: 13.5px;
        letter-spacing: 0.15em;

        i {
          display: inline-block;
          vertical-align: middle;
          font-size: 0.8em;
        }
      }

      &.open {
        a, a:focus {
          background: transparent;
        }
      }

      &.dropdown {
        .dropdown-menu {
          border-radius: 0;
          padding: 15px 0 0 0;
          margin: 0;
          border: 0;

          li {
            a {
              color: $brand-secondary;
              font-size: 12px;
              padding: 0 15px 15px 15px;
            }

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  @media (min-width: $screen-sm) {
    min-height: 94px;

    .navbar-brand {
      height: $navbar-height;

      img {
        height: 64px;
        width: 96px;
      }
    }

    .navbar-collapse {
      background: transparent;
      padding: 0 15px;
    }

    .navbar-nav {
      margin: 0 -15px;

      li {

        a {
          font-size: 11.5px;
          letter-spacing: 0.15em;

          i {
            display: none;
            vertical-align: middle;
            font-size: 0.8em;
          }
        }


        &.dropdown {
          .dropdown-menu {
            padding: 0;

            li {
              border-bottom: 1px solid rgba($brand-secondary, 0.3);

              a {
                color: $brand-primary;
                padding: 15px 22px;
                font-size: 10px;
              }
            }

            &:before {
              content: "";
              position: absolute;
              top: -15px;
              left: 40px;
              display: inline-block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 15px 15px 15px;
              border-color: transparent transparent white transparent;
            }
          }
        }
      }
    }
  }

  @media (min-width: $screen-md) {
    .navbar-nav {
      li {
        a {
          font-size: 13.5px;
          letter-spacing: 0.15em;

          i {
            display: inline-block;
          }
        }

        &.dropdown {
          .dropdown-menu {

            li {
              a {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}