// Toggle Trigger

.toggle-trigger {

  &:hover, &:focus {
    text-decoration: none;
  }

  .lead {
    color: $brand-secondary;
    margin: 0;
    border-top: 1px solid rgba($brand-secondary, 0.3);
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    transition: padding 0.5s;

    &:hover {
      padding: 20px 20px 20px 30px;
    }

    // Arrow up
     &:after {
      content: "⬆";
      font-family: "SS Glyphish", sans-serif;
       display: inline-block;
       vertical-align: middle;
       font-size: 18px;
       float: right;
       margin-top: 0.2em;
    }
  }

  &.collapsed {
    .lead {

      // Arrow Down
       &:after {
        content: "⬇";
        font-family: "SS Glyphish", sans-serif;
      }
    }
  }

  &.last {
    .lead {
      border-bottom: 1px solid rgba($brand-secondary, 0.3);
    }
  }
}

.collapse-inner {
  padding: 20px;
  border-top: 1px solid rgba($brand-secondary, 0.3);

  p {
    color: $brand-secondary;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}



// Streamfield Blocks
$block-padding: 2rem;

.intro-block {
  font-size: 2em;

  .rich-text {
    p:last-child {
      margin-bottom: 0;
    }
  }

  padding: $block-padding 0;

}

// 2/3/4 etc column blocks
.column-block {

  padding-top: $block-padding;
  padding-bottom: $block-padding;

  display: flex;
  align-items: stretch;

  h2,h3,h4 {
    &:first-child {
      margin-top: 0;
    }
  }


  [class^=col-] {
    border-right: 1px solid $gray-lighter;
    padding-right: 2.5em;
    padding-left: 2.5em;

    img {
      max-width: 100%;
      height: auto;
    }

    &:first-child {
      padding-left: $padding-base-horizontal;
    }

    &:last-child {
      border: 0;
      padding-right: $padding-base-horizontal;
    }

    @media screen and (max-width: $screen-xs-max){
      padding-left: $padding-base-horizontal;
      padding-right: $padding-base-horizontal;
    }

    p:last-child {
      @media screen and (min-width: $screen-sm) {
        margin-bottom: 0;
      }

    }

    p {
      img.full-width:last-child {
        margin-bottom: 0;
      }

    }


  }

  .col-sm-4 {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media screen and (max-width: $screen-xs-max){
      display: block;

    }
}


// GALLERY

.gallery-block {
  padding: $block-padding 0;
  background-color: $gray-lighter;
}

.gallery-list {
  .gallery-image {
    background: white;
    display: block;
    height: 300px;
    width: 100%;
    margin-bottom: 30px;
    background-size: cover!important;
    background-position: center!important;
    background-color: $gray-lighter !important;
    background-repeat: no-repeat !important;
    position: relative;
    opacity: 1;

    @media (min-width: $screen-sm) {
      height: 200px;
    }

    @media (min-width: $screen-md) {
      height: 300px;
    }

    .overlay {
      height: 100%;
      width: 100%;
      line-height: 300px;
      font-size: 3em;
      text-align: center;
      display: none;
      background: rgba(255,255,255,0.8);
      z-index: 2;
    }

    .caption {
      background: rgba(255,255,255,.8);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      padding: .5em 0;
      z-index: 1;
      opacity: 1;

      p {
        margin: 0;
        padding: 0;
        color: $gray;
      }

    }

    &:hover {
      .overlay {
        display: block;

        i {
          opacity: .5;
        }
      }
      .caption {
        display: none;
      }
    }


  }

  .row {
    &:last-child {
      .gallery-image {
        margin-bottom: 0;
      }
    }
  }
}

/* Lightbox */
body:after {
  content: url('../../img/lightbox/close.png') url('../../img/lightbox/loading.gif') url('../../img/lightbox/prev.png') url('../../img/lightbox/next.png');
  display: none;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  border-radius: 3px;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  background-color: white;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-container {
  padding: 4px;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url('../../img/lightbox/loading.gif') no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url('../../img/lightbox/prev.png') left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url('../../img/lightbox/next.png') right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url('../../img/lightbox/close.png') top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}


