// Global

html, body {
  height: 100%;
  width: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
}


// Typography



h1, h2 {
  font-weight: 300;
  font-size: 30px;

  small {
    display: block;
    font-family: $font-family-serif;
    font-size: 13.5px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: $brand-secondary;

    &:after {
      content: "";
      display: block;
      height: 1px;
      width: 53px;
      background: $brand-secondary;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  color: $brand-primary;
  margin-bottom: 37px;
}

h3 {
  color: $brand-primary;
  font-size: 18px;
  font-weight: 600;
}

p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: $brand-primary;
}

a {
  &:hover {
    text-decoration: none;
  }
}


// Body Content

.body-content {
  padding: 70px 0;
  position: relative;

  &.grey {
    background-image: linear-gradient(-135deg, #EEEEEE 0%, #FFFFFF 100%);
  }

  &.blue {
    background: $brand-primary;

    h2 {
      color: white;
    }

    p {
      color: white;
    }
  }

  @media (min-width: $screen-sm) {
    .intro {
      display: flex;
      align-items: center;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 25px;
    margin: 20px 0;

    li {
      position: relative;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      margin-bottom: 15px;
      color: $brand-primary;

      &:before {
        font-family: "SSGlyphish", sans-serif;
        content: '➡';
        color: $brand-primary;
        position: absolute;
        left: -25px;
        top: 1px;
        font-size: 0.8em;
      }
    }
  }

  .section-img {
    position: absolute;
    height: 100%;
    width: 40%;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover!important;
    background-position: center!important;
  }
}


// Modals

.modal {
  .modal-dialog {
    .modal-content {
      border-radius: 0;
      border: none;
      box-shadow: none;
    }

    .modal-header {
      height: 240px;
      background-size: cover!important;
      background-position: center!important;
      display: flex;
      align-items: center;
      padding: 0 20px;

      img {
        height: 19px;
        width: auto;
      }

      p {
        font-size: 17px;
        color: rgba(255,255,255,0.5);
        margin: 5px 0 0 0;
        font-family: $font-family-serif;
      }

      .close {
        top: 2px;
        right: -1px;
        position: absolute;
        font-size: 18px;
        line-height: 18px;
        color: white;
        background: $brand-primary;
        padding: 18px;
        opacity: 1;
      }

      @media (min-width: $screen-sm) {
        padding: 0 50px;
      }
    }

    .modal-body {
      padding: 24px;

      p {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .modal-footer {
      text-align: left;
      padding: 14px 24px;
      background-image: linear-gradient(-135deg, #EEEEEE 0%, #FFFFFF 100%);
      border: none;

      .contact {
        font-size: 14px;
        margin-top: 14px;
        display: inline-block;
        margin-right: 20px;
        font-weight: 300;

        i {
          color: $brand-secondary;
          font-size: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 7px;
        }
      }

      .btn {
        margin: 15px 0 0 0;
        float: none;
      }

      @media (min-width: $screen-sm) {
        .btn {
          float: right;
          margin: 0;
        }
      }
    }
  }

  &.investment-modal {
    .modal-dialog {

      .modal-header {
        position: relative;

        .overlay {
          background-image: linear-gradient(-135deg, rgba($brand-primary, 0.7) 0%, rgba(31, 44, 79, 0.28) 100%);
          position: absolute;
          top: 0; bottom: 0; right: 0; left: 0;
          display: flex;
          align-items: center;
          padding: 0 20px;

          @media (min-width: $screen-sm) {
            padding: 0 50px;
          }
        }

        .header-inner {
          h2 {
            color: white;
            font-size: 30px;
            margin: 0;
            
            img {
              height: 50px;
              width: auto;
              display: inline-block;
              vertical-align: middle;
              margin-right: 15px;
            }
          }
        }
      }

      .modal-body {
        p {
          color: $brand-primary;
        }
      }
    }
  }
}

.modal-backdrop
{
  opacity:0.8 !important;
}


// Video Embeds

.responsive-object {
  position: relative;
  padding-bottom: 67.5%;
  height: 0;
  margin: 10px 0;
  overflow: hidden;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
