@charset "UTF-8";

// Black Tie Light

@font-face {
  font-family: 'Black Tie';
  src: url('../fonts/BlackTie-Light-webfont.eot?v=1.0.0');
  src: url('../fonts/BlackTie-Light-webfont.eot?#iefix&v=1.0.0') format('embedded-opentype'), url('../fonts/BlackTie-Light-webfont.woff2?v=1.0.0') format('woff2'), url('../fonts/BlackTie-Light-webfont.woff?v=1.0.0') format('woff'), url('../fonts/BlackTie-Light-webfont.ttf?v=1.0.0') format('truetype'), url('../fonts/BlackTie-Light-webfont.svg?v=1.0.0#black_tielight') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome Brands';
  src: url('../fonts/FontAwesomeBrands-Regular-webfont.eot?v=1.0.0');
  src: url('../fonts/FontAwesomeBrands-Regular-webfont.eot?#iefix&v=1.0.0') format('embedded-opentype'), url('../fonts/FontAwesomeBrands-Regular-webfont.woff2?v=1.0.0') format('woff2'), url('../fonts/FontAwesomeBrands-Regular-webfont.woff?v=1.0.0') format('woff'), url('../fonts/FontAwesomeBrands-Regular-webfont.ttf?v=1.0.0') format('truetype'), url('../fonts/FontAwesomeBrands-Regular-webfont.svg?v=1.0.0#font_awesome_brandsregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

.bts,
.btb,
.btr,
.btl,
.fab {
  display: inline-block;
  font: normal normal normal 14px/1 "Black Tie";
  font-size: inherit;
  vertical-align: -14.28571429%;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.bts {
  font-weight: 900;
}

.btb {
  font-weight: 700;
}

.btl {
  font-weight: 200;
}

.fab {
  font-family: "Font Awesome Brands";
}

/* makes the font 25% smaller relative to the icon container */
.bt-sm {
  font-size: .7em;
  vertical-align: baseline;
}

/* makes the font 33% larger relative to the icon container */
.bt-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
}

.bt-2x {
  font-size: 2em;
}

.bt-3x {
  font-size: 3em;
}

.bt-4x {
  font-size: 4em;
}

.bt-5x {
  font-size: 5em;
}

.bt-lg,
.bt-2x,
.bt-3x,
.bt-4x,
.bt-5x {
  vertical-align: -30%;
}

.bt-fw {
  width: 1.28571429em;
  text-align: center;
}

.bt-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}

.bt-ul > li {
  position: relative;
}

.bt-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}

.bt-li.bt-lg {
  left: -2em;
}

.bt-border {
  padding: .2em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.bts.pull-left,
.btb.pull-left,
.btr.pull-left,
.btl.pull-left,
.fab.pull-left {
  margin-right: .3em;
}

.bts.pull-right,
.btb.pull-right,
.btr.pull-right,
.btl.pull-right,
.fab.pull-right {
  margin-left: .3em;
}

.bt-spin {
  -webkit-animation: bt-spin 2s infinite linear;
  animation: bt-spin 2s infinite linear;
}

.bt-pulse {
  -webkit-animation: bt-spin 1s infinite steps(8);
  animation: bt-spin 1s infinite steps(8);
}

@-webkit-keyframes bt-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes bt-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.bt-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.bt-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.bt-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.bt-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.bt-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

:root .bt-rotate-90,
:root .bt-rotate-180,
:root .bt-rotate-270,
:root .bt-flip-horizontal,
:root .bt-flip-vertical {
  filter: none;
}

.bt-stack {
  position: relative;
  display: inline-block;
  width: 1.28571429em;
  height: 1em;
  line-height: 1em;
  vertical-align: baseline;
}

.bt-stack-sm {
  position: absolute;
  top: 0;
  left: 0;
  line-height: inherit;
  font-size: .5em;
}

.bt-stack-1x,
.bt-stack-sm {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.bt-inverse {
  color: #ffffff;
}

/* Black Tie uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.bt-bars:before {
  content: "\f000";
}

.bt-envelope:before {
  content: "\f001";
}

.bt-search:before {
  content: "\f002";
}

.bt-search-plus:before {
  content: "\f003";
}

.bt-search-minus:before {
  content: "\f004";
}

.bt-phone:before {
  content: "\f005";
}

.bt-comment:before {
  content: "\f007";
}

.bt-commenting:before {
  content: "\f008";
}

.bt-comments:before {
  content: "\f009";
}

.bt-rss:before {
  content: "\f00a";
}

.bt-times:before {
  content: "\f00c";
}

.bt-times-circle:before {
  content: "\f00d";
}

.bt-clock:before {
  content: "\f00e";
}

.bt-star:before {
  content: "\f010";
}

.bt-star-half:before {
  content: "\f011";
}

.bt-check:before {
  content: "\f012";
}

.bt-check-circle:before {
  content: "\f013";
}

.bt-check-square:before {
  content: "\f014";
}

.bt-th:before {
  content: "\f015";
}

.bt-th-large:before {
  content: "\f016";
}

.bt-heart:before {
  content: "\f017";
}

.bt-heart-half:before {
  content: "\f018";
}

.bt-calendar:before {
  content: "\f019";
}

.bt-shopping-cart:before {
  content: "\f01a";
}

.bt-plus:before {
  content: "\f01b";
}

.bt-plus-circle:before {
  content: "\f01c";
}

.bt-plus-square:before {
  content: "\f01d";
}

.bt-pen:before {
  content: "\f01e";
}

.bt-minus:before {
  content: "\f021";
}

.bt-minus-circle:before {
  content: "\f022";
}

.bt-minus-square:before {
  content: "\f023";
}

.bt-pencil:before {
  content: "\f024";
}

.bt-edit:before {
  content: "\f025";
}

.bt-thumbs-up:before {
  content: "\f026";
}

.bt-thumbs-down:before {
  content: "\f027";
}

.bt-gear:before {
  content: "\f028";
}

.bt-trash:before {
  content: "\f029";
}

.bt-file:before {
  content: "\f02a";
}

.bt-info-circle:before {
  content: "\f02b";
}

.bt-label:before {
  content: "\f02c";
}

.bt-rocket:before {
  content: "\f02d";
}

.bt-book:before {
  content: "\f02e";
}

.bt-book-open:before {
  content: "\f02f";
}

.bt-notebook:before {
  content: "\f030";
}

.bt-camera:before {
  content: "\f031";
}

.bt-folder:before {
  content: "\f032";
}

.bt-quote-left:before {
  content: "\f036";
}

.bt-quote-right:before {
  content: "\f037";
}

.bt-eye:before {
  content: "\f038";
}

.bt-lock:before {
  content: "\f039";
}

.bt-lock-open:before {
  content: "\f03a";
}

.bt-gift:before {
  content: "\f03b";
}

.bt-spinner-clock:before {
  content: "\f03c";
}

.bt-spinner:before {
  content: "\f03d";
}

.bt-wrench:before {
  content: "\f03e";
}

.bt-cloud:before {
  content: "\f040";
}

.bt-cloud-upload:before {
  content: "\f041";
}

.bt-cloud-download:before {
  content: "\f042";
}

.bt-sync:before {
  content: "\f043";
}

.bt-question-circle:before {
  content: "\f044";
}

.bt-share:before {
  content: "\f045";
}

.bt-briefcase:before {
  content: "\f046";
}

.bt-money:before {
  content: "\f047";
}

.bt-megaphone:before {
  content: "\f048";
}

.bt-sign-in:before {
  content: "\f049";
}

.bt-sign-out:before {
  content: "\f04a";
}

.bt-film:before {
  content: "\f04b";
}

.bt-trophy:before {
  content: "\f04c";
}

.bt-code:before {
  content: "\f04d";
}

.bt-light-bulb:before {
  content: "\f04e";
}

.bt-print:before {
  content: "\f050";
}

.bt-fax:before {
  content: "\f051";
}

.bt-video:before {
  content: "\f052";
}

.bt-signal:before {
  content: "\f053";
}

.bt-sitemap:before {
  content: "\f054";
}

.bt-upload:before {
  content: "\f055";
}

.bt-download:before {
  content: "\f056";
}

.bt-key:before {
  content: "\f057";
}

.bt-mug:before {
  content: "\f058";
}

.bt-bookmark:before {
  content: "\f059";
}

.bt-flag:before {
  content: "\f05a";
}

.bt-external-link:before {
  content: "\f05b";
}

.bt-smile:before {
  content: "\f05c";
}

.bt-frown:before {
  content: "\f05d";
}

.bt-meh:before {
  content: "\f05e";
}

.bt-magic:before {
  content: "\f060";
}

.bt-bolt:before {
  content: "\f061";
}

.bt-exclamation-triangle:before {
  content: "\f062";
}

.bt-exclamation-circle:before {
  content: "\f063";
}

.bt-flask:before {
  content: "\f064";
}

.bt-music:before {
  content: "\f065";
}

.bt-push-pin:before {
  content: "\f066";
}

.bt-shield:before {
  content: "\f067";
}

.bt-sort:before {
  content: "\f068";
}

.bt-reply:before {
  content: "\f069";
}

.bt-forward:before {
  content: "\f06a";
}

.bt-reply-all:before {
  content: "\f06b";
}

.bt-forward-all:before {
  content: "\f06c";
}

.bt-bell:before {
  content: "\f06d";
}

.bt-bell-off:before {
  content: "\f06e";
}

.bt-ban:before {
  content: "\f070";
}

.bt-database:before {
  content: "\f071";
}

.bt-hard-drive:before {
  content: "\f072";
}

.bt-merge:before {
  content: "\f073";
}

.bt-fork:before {
  content: "\f074";
}

.bt-wifi:before {
  content: "\f075";
}

.bt-paper-plane:before {
  content: "\f076";
}

.bt-inbox:before {
  content: "\f077";
}

.bt-fire:before {
  content: "\f078";
}

.bt-play:before {
  content: "\f079";
}

.bt-pause:before {
  content: "\f07a";
}

.bt-stop:before {
  content: "\f08b";
}

.bt-play-circle:before {
  content: "\f07b";
}

.bt-next:before {
  content: "\f07c";
}

.bt-previous:before {
  content: "\f07d";
}

.bt-repeat:before {
  content: "\f07e";
}

.bt-fast-forward:before {
  content: "\f080";
}

.bt-fast-reverse:before {
  content: "\f081";
}

.bt-volume:before {
  content: "\f082";
}

.bt-volume-off:before {
  content: "\f083";
}

.bt-volume-up:before {
  content: "\f084";
}

.bt-volume-down:before {
  content: "\f085";
}

.bt-maximize:before {
  content: "\f086";
}

.bt-minimize:before {
  content: "\f087";
}

.bt-closed-captions:before {
  content: "\f088";
}

.bt-shuffle:before {
  content: "\f089";
}

.bt-triangle:before {
  content: "\f08a";
}

.bt-square:before {
  content: "\f08b";
}

.bt-circle:before {
  content: "\f08c";
}

.bt-hexagon:before {
  content: "\f08d";
}

.bt-octagon:before {
  content: "\f08e";
}

.bt-angle-up:before {
  content: "\f090";
}

.bt-angle-down:before {
  content: "\f091";
}

.bt-angle-left:before {
  content: "\f092";
}

.bt-angle-right:before {
  content: "\f093";
}

.bt-angles-up:before {
  content: "\f094";
}

.bt-angles-down:before {
  content: "\f095";
}

.bt-angles-left:before {
  content: "\f096";
}

.bt-angles-right:before {
  content: "\f097";
}

.bt-arrow-up:before {
  content: "\f098";
}

.bt-arrow-down:before {
  content: "\f099";
}

.bt-arrow-left:before {
  content: "\f09a";
}

.bt-arrow-right:before {
  content: "\f09b";
}

.bt-bar-chart:before {
  content: "\f09c";
}

.bt-pie-chart:before {
  content: "\f09d";
}

.bt-circle-arrow-up:before {
  content: "\f0a0";
}

.bt-circle-arrow-down:before {
  content: "\f0a1";
}

.bt-circle-arrow-left:before {
  content: "\f0a2";
}

.bt-circle-arrow-right:before {
  content: "\f0a3";
}

.bt-caret-up:before {
  content: "\f0a4";
}

.bt-caret-down:before {
  content: "\f0a5";
}

.bt-caret-left:before {
  content: "\f0a6";
}

.bt-caret-right:before {
  content: "\f0a7";
}

.bt-long-arrow-up:before {
  content: "\f0a8";
}

.bt-long-arrow-down:before {
  content: "\f0a9";
}

.bt-long-arrow-left:before {
  content: "\f0aa";
}

.bt-long-arrow-right:before {
  content: "\f0ab";
}

.bt-Bold:before {
  content: "\f0ac";
}

.bt-italic:before {
  content: "\f0ad";
}

.bt-underline:before {
  content: "\f0ae";
}

.bt-link:before {
  content: "\f0b0";
}

.bt-paper-clip:before {
  content: "\f0b1";
}

.bt-align-left:before {
  content: "\f0b2";
}

.bt-align-center:before {
  content: "\f0b3";
}

.bt-align-right:before {
  content: "\f0b4";
}

.bt-align-justify:before {
  content: "\f0b5";
}

.bt-cut:before {
  content: "\f0b6";
}

.bt-copy:before {
  content: "\f0b7";
}

.bt-paste:before {
  content: "\f0b8";
}

.bt-photo:before {
  content: "\f0b9";
}

.bt-table:before {
  content: "\f0ba";
}

.bt-ulist:before {
  content: "\f0bb";
}

.bt-olist:before {
  content: "\f0bc";
}

.bt-indent:before {
  content: "\f0bd";
}

.bt-outdent:before {
  content: "\f0be";
}

.bt-undo:before {
  content: "\f0c0";
}

.bt-redo:before {
  content: "\f0c1";
}

.bt-sup:before {
  content: "\f0c2";
}

.bt-sub:before {
  content: "\f0c3";
}

.bt-text-size:before {
  content: "\f0c4";
}

.bt-text-color:before {
  content: "\f0c5";
}

.bt-remove-formatting:before {
  content: "\f0c6";
}

.bt-blockquote:before {
  content: "\f036";
}

.bt-globe:before {
  content: "\f0c7";
}

.bt-map:before {
  content: "\f0c8";
}

.bt-map-arrow:before {
  content: "\f0c9";
}

.bt-map-marker:before {
  content: "\f0ca";
}

.bt-map-pin:before {
  content: "\f0cb";
}

.bt-home:before {
  content: "\f0cc";
}

.bt-building:before {
  content: "\f0cd";
}

.bt-industry:before {
  content: "\f0ce";
}

.bt-desktop:before {
  content: "\f0d0";
}

.bt-laptop:before {
  content: "\f0d1";
}

.bt-tablet:before {
  content: "\f0d2";
}

.bt-mobile:before {
  content: "\f0d3";
}

.bt-tv:before {
  content: "\f0d4";
}

.bt-radio-checked:before {
  content: "\f0d5";
}

.bt-radio-unchecked:before {
  content: "\f08c";
}

.bt-checkbox-checked:before {
  content: "\f014";
}

.bt-checkbox-unchecked:before {
  content: "\f08b";
}

.bt-checkbox-intermediate:before {
  content: "\f023";
}

.bt-user:before {
  content: "\f0d6";
}

.bt-user-male:before {
  content: "\f0d6";
}

.bt-user-female:before {
  content: "\f0d7";
}

.bt-crown:before {
  content: "\f0d8";
}

.bt-credit-card:before {
  content: "\f0d9";
}

.bt-strikethrough:before {
  content: "\f0da";
}

.bt-eject:before {
  content: "\f0db";
}

.bt-ellipsis-h:before {
  content: "\f0dc";
}

.bt-ellipsis-v:before {
  content: "\f0dd";
}

.fab-facebook:before {
  content: "\f000";
}

.fab-facebook-alt:before {
  content: "\f001";
}

.fab-twitter:before {
  content: "\f002";
}

.fab-linkedin:before {
  content: "\f003";
}

.fab-linkedin-alt:before {
  content: "\f004";
}

.fab-instagram:before {
  content: "\f005";
}

.fab-github:before {
  content: "\f006";
}

.fab-github-alt:before {
  content: "\f007";
}

.fab-googleplus:before {
  content: "\f008";
}

.fab-googleplus-alt:before {
  content: "\f009";
}

.fab-pinterest:before {
  content: "\f00a";
}

.fab-pinterest-alt:before {
  content: "\f00b";
}

.fab-tumblr:before {
  content: "\f00c";
}

.fab-tumblr-alt:before {
  content: "\f00d";
}

.fab-bitcoin:before {
  content: "\f010";
}

.fab-bitcoin-alt:before {
  content: "\f011";
}

.fab-dropbox:before {
  content: "\f012";
}

.fab-stackexchange:before {
  content: "\f013";
}

.fab-stackoverflow:before {
  content: "\f014";
}

.fab-flickr:before {
  content: "\f015";
}

.fab-flickr-alt:before {
  content: "\f016";
}

.fab-bitbucket:before {
  content: "\f017";
}

.fab-html5:before {
  content: "\f018";
}

.fab-css3:before {
  content: "\f019";
}

.fab-apple:before {
  content: "\f01a";
}

.fab-windows:before {
  content: "\f01b";
}

.fab-android:before {
  content: "\f01c";
}

.fab-linux:before {
  content: "\f01d";
}

.fab-dribbble:before {
  content: "\f01e";
}

.fab-youtube:before {
  content: "\f021";
}

.fab-skype:before {
  content: "\f022";
}

.fab-foursquare:before {
  content: "\f023";
}

.fab-trello:before {
  content: "\f024";
}

.fab-maxcdn:before {
  content: "\f025";
}

.fab-gittip:before,
.fab-gratipay:before {
  content: "\f026";
}

.fab-vimeo:before {
  content: "\f027";
}

.fab-vimeo-alt:before {
  content: "\f028";
}

.fab-slack:before {
  content: "\f029";
}

.fab-wordpress:before {
  content: "\f02a";
}

.fab-wordpress-alt:before {
  content: "\f02b";
}

.fab-openid:before {
  content: "\f02c";
}

.fab-yahoo:before {
  content: "\f02d";
}

.fab-yahoo-alt:before {
  content: "\f02e";
}

.fab-reddit:before {
  content: "\f02f";
}

.fab-google:before {
  content: "\f030";
}

.fab-google-alt:before {
  content: "\f031";
}

.fab-stumbleupon:before {
  content: "\f032";
}

.fab-stumbleupon-alt:before {
  content: "\f033";
}

.fab-delicious:before {
  content: "\f034";
}

.fab-digg:before {
  content: "\f035";
}

.fab-piedpiper:before {
  content: "\f036";
}

.fab-piedpiper-alt:before {
  content: "\f037";
}

.fab-drupal:before {
  content: "\f038";
}

.fab-joomla:before {
  content: "\f039";
}

.fab-behance:before {
  content: "\f03a";
}

.fab-steam:before {
  content: "\f03b";
}

.fab-steam-alt:before {
  content: "\f03c";
}

.fab-spotify:before {
  content: "\f03d";
}

.fab-deviantart:before {
  content: "\f03e";
}

.fab-soundcloud:before {
  content: "\f040";
}

.fab-vine:before {
  content: "\f041";
}

.fab-codepen:before {
  content: "\f042";
}

.fab-jsfiddle:before {
  content: "\f043";
}

.fab-rebel:before {
  content: "\f044";
}

.fab-empire:before {
  content: "\f045";
}

.fab-git:before {
  content: "\f046";
}

.fab-hackernews:before {
  content: "\f047";
}

.fab-hackernews-alt:before {
  content: "\f048";
}

.fab-slideshare:before {
  content: "\f049";
}

.fab-twitch:before {
  content: "\f04a";
}

.fab-yelp:before {
  content: "\f04b";
}

.fab-paypal:before {
  content: "\f04c";
}

.fab-google-wallet:before {
  content: "\f04d";
}

.fab-angellist:before {
  content: "\f04e";
}

.fab-cc-visa:before {
  content: "\f050";
}

.fab-cc-mastercard:before {
  content: "\f051";
}

.fab-cc-discover:before {
  content: "\f052";
}

.fab-cc-amex:before {
  content: "\f053";
}

.fab-cc-paypal:before {
  content: "\f054";
}

.fab-cc-stripe:before {
  content: "\f055";
}

.fab-lastfm:before {
  content: "\f056";
}

.fab-whatsapp:before {
  content: "\f057";
}

.fab-medium:before {
  content: "\f058";
}

.fab-meanpath:before {
  content: "\f059";
}

.fab-meanpath-alt:before {
  content: "\f05a";
}

.fab-pagelines:before {
  content: "\f05b";
}

.fab-ioxhost:before {
  content: "\f060";
}

.fab-buysellads:before {
  content: "\f061";
}

.fab-buysellads-alt:before {
  content: "\f062";
}

.fab-connectdevelop:before {
  content: "\f063";
}

.fab-dashcube:before {
  content: "\f064";
}

.fab-forumbee:before {
  content: "\f065";
}

.fab-leanpub:before {
  content: "\f066";
}

.fab-sellsy:before {
  content: "\f067";
}

.fab-shirtsinbulk:before {
  content: "\f068";
}

.fab-simplybuilt:before {
  content: "\f069";
}

.fab-skyatlas:before {
  content: "\f06a";
}

.fab-viacoin:before {
  content: "\f06b";
}

.fab-codiepie:before {
  content: "\f06c";
}

.fab-queue:before {
  content: "\f06d";
}

.fab-queue-alt:before {
  content: "\f06e";
}

.fab-fonticons:before {
  content: "\f070";
}

.fab-fonticons-alt:before {
  content: "\f071";
}

.fab-blacktie:before {
  content: "\f072";
}

.fab-blacktie-alt:before {
  content: "\f073";
}

.fab-xing:before {
  content: "\f090";
}

.fab-vk:before {
  content: "\f091";
}

.fab-weibo:before {
  content: "\f092";
}

.fab-renren:before {
  content: "\f093";
}

.fab-tencent-weibo:before {
  content: "\f094";
}

.fab-qq:before {
  content: "\f095";
}

.fab-wechat:before,
.fab-weixin:before {
  content: "\f096";
}

@font-face {
  font-family: "SSGlyphish";
  src: url('../fonts/ss-glyphish-outlined.eot');
  src: url('../fonts/ss-glyphish-outlined.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ss-glyphish-outlined.woff') format('woff'),
  url('../fonts/ss-glyphish-outlined.ttf') format('truetype'),
  url('../fonts/ss-glyphish-outlined.svg#SSGlyphishOutlined') format('svg');
  font-weight: 400;
  font-style: normal;
}

/* This triggers a redraw in IE to Fix IE8's :before content rendering. */
html:hover [class^="ss-"] {
  -ms-zoom: 1;
}

.ss-icon, .ss-icon.ss-glyphish-outlined,
[class^="ss-"]:before, [class*=" ss-"]:before,
[class^="ss-"].ss-glyphish-outlined:before, [class*=" ss-"].ss-glyphish-outlined:before,
[class^="ss-"].right:after, [class*=" ss-"].right:after,
[class^="ss-"].ss-glyphish-outined.right:after, [class*=" ss-"].ss-glyphish-outlined.right:after {
  font-family: "SSGlyphish";
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  /*-webkit-font-feature-settings: "liga"; Currently broken in Chrome >= v22. Falls back to text-rendering. Safari is unaffected. */
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="ss-"].right:before,
[class*=" ss-"].right:before {
  display: none;
  content: '';
}

.ss-cursor:before, .ss-cursor.right:after {
  content: ''
}

.ss-crosshair:before, .ss-crosshair.right:after {
  content: '⌖'
}

.ss-search:before, .ss-search.right:after {
  content: '🔎'
}

.ss-zoomin:before, .ss-zoomin.right:after {
  content: ''
}

.ss-zoomout:before, .ss-zoomout.right:after {
  content: ''
}

.ss-view:before, .ss-view.right:after {
  content: '👁'
}

.ss-binoculars:before, .ss-binoculars.right:after {
  content: ''
}

.ss-incognito:before, .ss-incognito.right:after {
  content: ''
}

.ss-radar:before, .ss-radar.right:after {
  content: ''
}

.ss-attach:before, .ss-attach.right:after {
  content: '📎'
}

.ss-link:before, .ss-link.right:after {
  content: '🔗'
}

.ss-write:before, .ss-write.right:after {
  content: '✎'
}

.ss-compose:before, .ss-compose.right:after {
  content: '📝'
}

.ss-lock:before, .ss-lock.right:after {
  content: '🔒'
}

.ss-unlock:before, .ss-unlock.right:after {
  content: '🔓'
}

.ss-combinationlock:before, .ss-combinationlock.right:after {
  content: ''
}

.ss-key:before, .ss-key.right:after {
  content: '🔑'
}

.ss-keyhole:before, .ss-keyhole.right:after {
  content: ''
}

.ss-vip:before, .ss-vip.right:after {
  content: ''
}

.ss-trash:before, .ss-trash.right:after {
  content: '🗑'
}

.ss-target:before, .ss-target.right:after {
  content: '◎'
}

.ss-bullseye:before, .ss-bullseye.right:after {
  content: '🎯'
}

.ss-lightning:before, .ss-lightning.right:after {
  content: '☇'
}

.ss-radioactive:before, .ss-radioactive.right:after {
  content: '☢'
}

.ss-skull:before, .ss-skull.right:after {
  content: '💀'
}

.ss-skullandcrossbones:before, .ss-skullandcrossbones.right:after {
  content: '☠'
}

.ss-tag:before, .ss-tag.right:after {
  content: '🏷'
}

.ss-tags:before, .ss-tags.right:after {
  content: ''
}

.ss-pricetag:before, .ss-pricetag.right:after {
  content: ''
}

.ss-flag:before, .ss-flag.right:after {
  content: '⚑'
}

.ss-like:before, .ss-like.right:after {
  content: '👍'
}

.ss-dislike:before, .ss-dislike.right:after {
  content: '👎'
}

.ss-heart:before, .ss-heart.right:after {
  content: '♥'
}

.ss-star:before, .ss-star.right:after {
  content: '⋆'
}

.ss-archive:before, .ss-archive.right:after {
  content: '🗃'
}

.ss-badge:before, .ss-badge.right:after {
  content: '📛'
}

.ss-trophy:before, .ss-trophy.right:after {
  content: '🏆'
}

.ss-medal:before, .ss-medal.right:after {
  content: '🎖'
}

.ss-crown:before, .ss-crown.right:after {
  content: '👑'
}

.ss-layers:before, .ss-layers.right:after {
  content: ''
}

.ss-layergroup:before, .ss-layergroup.right:after {
  content: ''
}

.ss-cut:before, .ss-cut.right:after {
  content: '✂'
}

.ss-magicwand:before, .ss-magicwand.right:after {
  content: ''
}

.ss-clipboard:before, .ss-clipboard.right:after {
  content: '📋'
}

.ss-ruler:before, .ss-ruler.right:after {
  content: '📏'
}

.ss-brush:before, .ss-brush.right:after {
  content: ''
}

.ss-paintroller:before, .ss-paintroller.right:after {
  content: ''
}

.ss-rubberstamp:before, .ss-rubberstamp.right:after {
  content: ''
}

.ss-palette:before, .ss-palette.right:after {
  content: '🎨'
}

.ss-phone:before, .ss-phone.right:after {
  content: '📞'
}

.ss-voicemail:before, .ss-voicemail.right:after {
  content: '⌕'
}

.ss-megaphone:before, .ss-megaphone.right:after {
  content: '📢'
}

.ss-at:before, .ss-at.right:after {
  content: '@'
}

.ss-reply:before, .ss-reply.right:after {
  content: '↩'
}

.ss-replyall:before, .ss-replyall.right:after {
  content: ''
}

.ss-mailbox:before, .ss-mailbox.right:after {
  content: '📫'
}

.ss-send:before, .ss-send.right:after {
  content: '🖅'
}

.ss-mail:before, .ss-mail.right:after {
  content: '✉'
}

.ss-inbox:before, .ss-inbox.right:after {
  content: '📥'
}

.ss-stamp:before, .ss-stamp.right:after {
  content: ''
}

.ss-chat:before, .ss-chat.right:after {
  content: '💬'
}

.ss-chats:before, .ss-chats.right:after {
  content: '🗪'
}

.ss-ellipsischat:before, .ss-ellipsischat.right:after {
  content: ''
}

.ss-ellipsis:before, .ss-ellipsis.right:after {
  content: '…'
}

.ss-smile:before, .ss-smile.right:after {
  content: '☻'
}

.ss-user:before, .ss-user.right:after {
  content: '👤'
}

.ss-femaleuser:before, .ss-femaleuser.right:after {
  content: '👧'
}

.ss-users:before, .ss-users.right:after {
  content: '👥'
}

.ss-baby:before, .ss-baby.right:after {
  content: '👶'
}

.ss-alien:before, .ss-alien.right:after {
  content: '👽'
}

.ss-ghost:before, .ss-ghost.right:after {
  content: '👻'
}

.ss-footsteps:before, .ss-footsteps.right:after {
  content: '👣'
}

.ss-usergroup:before, .ss-usergroup.right:after {
  content: ''
}

.ss-contacts:before, .ss-contacts.right:after {
  content: '📇'
}

.ss-userprofile:before, .ss-userprofile.right:after {
  content: ''
}

.ss-cart:before, .ss-cart.right:after {
  content: ''
}

.ss-shoppingbag:before, .ss-shoppingbag.right:after {
  content: '🛍'
}

.ss-gift:before, .ss-gift.right:after {
  content: '🎁'
}

.ss-store:before, .ss-store.right:after {
  content: '🏪'
}

.ss-barcode:before, .ss-barcode.right:after {
  content: ''
}

.ss-creditcard:before, .ss-creditcard.right:after {
  content: '💳'
}

.ss-creditcards:before, .ss-creditcards.right:after {
  content: ''
}

.ss-banknote:before, .ss-banknote.right:after {
  content: '💵'
}

.ss-currency:before, .ss-currency.right:after {
  content: '💱'
}

.ss-piggybank:before, .ss-piggybank.right:after {
  content: ''
}

.ss-calculator:before, .ss-calculator.right:after {
  content: '🖩'
}

.ss-tally:before, .ss-tally.right:after {
  content: ''
}

.ss-bank:before, .ss-bank.right:after {
  content: '🏦'
}

.ss-scales:before, .ss-scales.right:after {
  content: '⚖'
}

.ss-gavel:before, .ss-gavel.right:after {
  content: ''
}

.ss-meeting:before, .ss-meeting.right:after {
  content: ''
}

.ss-presentation:before, .ss-presentation.right:after {
  content: ''
}

.ss-piechart:before, .ss-piechart.right:after {
  content: ''
}

.ss-barchart:before, .ss-barchart.right:after {
  content: '📊'
}

.ss-activity:before, .ss-activity.right:after {
  content: ''
}

.ss-flowchart:before, .ss-flowchart.right:after {
  content: ''
}

.ss-stock:before, .ss-stock.right:after {
  content: '🗠'
}

.ss-box:before, .ss-box.right:after {
  content: '📦'
}

.ss-crate:before, .ss-crate.right:after {
  content: ''
}

.ss-handtruck:before, .ss-handtruck.right:after {
  content: ''
}

.ss-home:before, .ss-home.right:after {
  content: '⌂'
}

.ss-fence:before, .ss-fence.right:after {
  content: ''
}

.ss-buildings:before, .ss-buildings.right:after {
  content: '🏢'
}

.ss-hotel:before, .ss-hotel.right:after {
  content: '🏨'
}

.ss-warehouse:before, .ss-warehouse.right:after {
  content: ''
}

.ss-castle:before, .ss-castle.right:after {
  content: '🏰'
}

.ss-globe:before, .ss-globe.right:after {
  content: '🌐'
}

.ss-earth:before, .ss-earth.right:after {
  content: '🌎'
}

.ss-navigate:before, .ss-navigate.right:after {
  content: ''
}

.ss-compassnavigate:before, .ss-compassnavigate.right:after {
  content: ''
}

.ss-compass:before, .ss-compass.right:after {
  content: ''
}

.ss-signpost:before, .ss-signpost.right:after {
  content: ''
}

.ss-map:before, .ss-map.right:after {
  content: ''
}

.ss-location:before, .ss-location.right:after {
  content: ''
}

.ss-pin:before, .ss-pin.right:after {
  content: '📍'
}

.ss-pushpin:before, .ss-pushpin.right:after {
  content: '📌'
}

.ss-maplocation:before, .ss-maplocation.right:after {
  content: ''
}

.ss-floppydisk:before, .ss-floppydisk.right:after {
  content: '💾'
}

.ss-puzzle:before, .ss-puzzle.right:after {
  content: ''
}

.ss-install:before, .ss-install.right:after {
  content: ''
}

.ss-window:before, .ss-window.right:after {
  content: '🗔'
}

.ss-windows:before, .ss-windows.right:after {
  content: '🗗'
}

.ss-commandline:before, .ss-commandline.right:after {
  content: ''
}

.ss-bug:before, .ss-bug.right:after {
  content: '🐛'
}

.ss-database:before, .ss-database.right:after {
  content: ''
}

.ss-music:before, .ss-music.right:after {
  content: '♫'
}

.ss-eighthnote:before, .ss-eighthnote.right:after {
  content: '♪'
}

.ss-guitar:before, .ss-guitar.right:after {
  content: '🎸'
}

.ss-piano:before, .ss-piano.right:after {
  content: '🎹'
}

.ss-pianokeys:before, .ss-pianokeys.right:after {
  content: ''
}

.ss-trumpet:before, .ss-trumpet.right:after {
  content: '🎺'
}

.ss-mic:before, .ss-mic.right:after {
  content: '🎤'
}

.ss-studiomic:before, .ss-studiomic.right:after {
  content: '🎙'
}

.ss-headphones:before, .ss-headphones.right:after {
  content: '🎧'
}

.ss-mutevolume:before, .ss-mutevolume.right:after {
  content: ''
}

.ss-volume:before, .ss-volume.right:after {
  content: '🔈'
}

.ss-lowvolume:before, .ss-lowvolume.right:after {
  content: '🔉'
}

.ss-highvolume:before, .ss-highvolume.right:after {
  content: '🔊'
}

.ss-ipod:before, .ss-ipod.right:after {
  content: ''
}

.ss-stereo:before, .ss-stereo.right:after {
  content: ''
}

.ss-speaker:before, .ss-speaker.right:after {
  content: ''
}

.ss-vinylrecord:before, .ss-vinylrecord.right:after {
  content: ''
}

.ss-disc:before, .ss-disc.right:after {
  content: '💿'
}

.ss-playlist:before, .ss-playlist.right:after {
  content: ''
}

.ss-airplay:before, .ss-airplay.right:after {
  content: ''
}

.ss-camera:before, .ss-camera.right:after {
  content: '📷'
}

.ss-picture:before, .ss-picture.right:after {
  content: '🌄'
}

.ss-pictures:before, .ss-pictures.right:after {
  content: ''
}

.ss-video:before, .ss-video.right:after {
  content: '📹'
}

.ss-film:before, .ss-film.right:after {
  content: '🎞'
}

.ss-filmroll:before, .ss-filmroll.right:after {
  content: ''
}

.ss-widescreenfilm:before, .ss-widescreenfilm.right:after {
  content: ''
}

.ss-clapboard:before, .ss-clapboard.right:after {
  content: '🎬'
}

.ss-tv:before, .ss-tv.right:after {
  content: '📺'
}

.ss-flatscreen:before, .ss-flatscreen.right:after {
  content: ''
}

.ss-videogame:before, .ss-videogame.right:after {
  content: '🎮'
}

.ss-repeat:before, .ss-repeat.right:after {
  content: '🔁'
}

.ss-replay:before, .ss-replay.right:after {
  content: '↺'
}

.ss-shuffle:before, .ss-shuffle.right:after {
  content: '🔀'
}

.ss-filecabinet:before, .ss-filecabinet.right:after {
  content: '🗄'
}

.ss-storagebox:before, .ss-storagebox.right:after {
  content: ''
}

.ss-index:before, .ss-index.right:after {
  content: '🗂'
}

.ss-page:before, .ss-page.right:after {
  content: '📃'
}

.ss-book:before, .ss-book.right:after {
  content: '📕'
}

.ss-openbook:before, .ss-openbook.right:after {
  content: '📖'
}

.ss-notebook:before, .ss-notebook.right:after {
  content: '📓'
}

.ss-spiralbound:before, .ss-spiralbound.right:after {
  content: '🗒'
}

.ss-newspaper:before, .ss-newspaper.right:after {
  content: '📰'
}

.ss-notepad:before, .ss-notepad.right:after {
  content: ''
}

.ss-cards:before, .ss-cards.right:after {
  content: ''
}

.ss-notice:before, .ss-notice.right:after {
  content: ''
}

.ss-grid:before, .ss-grid.right:after {
  content: ''
}

.ss-rows:before, .ss-rows.right:after {
  content: ''
}

.ss-menu:before, .ss-menu.right:after {
  content: ''
}

.ss-filter:before, .ss-filter.right:after {
  content: ''
}

.ss-ascending:before, .ss-ascending.right:after {
  content: ''
}

.ss-descending:before, .ss-descending.right:after {
  content: ''
}

.ss-desktop:before, .ss-desktop.right:after {
  content: '💻'
}

.ss-laptop:before, .ss-laptop.right:after {
  content: ''
}

.ss-tablet:before, .ss-tablet.right:after {
  content: ''
}

.ss-smartphone:before, .ss-smartphone.right:after {
  content: ''
}

.ss-cell:before, .ss-cell.right:after {
  content: '📱'
}

.ss-battery:before, .ss-battery.right:after {
  content: '🔋'
}

.ss-door:before, .ss-door.right:after {
  content: '🚪'
}

.ss-washer:before, .ss-washer.right:after {
  content: ''
}

.ss-birdhouse:before, .ss-birdhouse.right:after {
  content: ''
}

.ss-doghouse:before, .ss-doghouse.right:after {
  content: ''
}

.ss-lightbulb:before, .ss-lightbulb.right:after {
  content: '💡'
}

.ss-lamp:before, .ss-lamp.right:after {
  content: ''
}

.ss-spraycan:before, .ss-spraycan.right:after {
  content: ''
}

.ss-spraybottle:before, .ss-spraybottle.right:after {
  content: ''
}

.ss-chair:before, .ss-chair.right:after {
  content: ''
}

.ss-frame:before, .ss-frame.right:after {
  content: '🖼'
}

.ss-flowerframe:before, .ss-flowerframe.right:after {
  content: ''
}

.ss-atom:before, .ss-atom.right:after {
  content: '⚛'
}

.ss-telescope:before, .ss-telescope.right:after {
  content: '🔭'
}

.ss-balloon:before, .ss-balloon.right:after {
  content: '🎈'
}

.ss-fuzzydice:before, .ss-fuzzydice.right:after {
  content: ''
}

.ss-magiclamp:before, .ss-magiclamp.right:after {
  content: ''
}

.ss-slingshot:before, .ss-slingshot.right:after {
  content: ''
}

.ss-bomb:before, .ss-bomb.right:after {
  content: '💣'
}

.ss-gun:before, .ss-gun.right:after {
  content: '🔫'
}

.ss-sword:before, .ss-sword.right:after {
  content: ''
}

.ss-swords:before, .ss-swords.right:after {
  content: '⚔'
}

.ss-bowandarrow:before, .ss-bowandarrow.right:after {
  content: ''
}

.ss-targetdummy:before, .ss-targetdummy.right:after {
  content: ''
}

.ss-downloadcloud:before, .ss-downloadcloud.right:after {
  content: ''
}

.ss-download:before, .ss-download.right:after {
  content: ''
}

.ss-downloadbox:before, .ss-downloadbox.right:after {
  content: ''
}

.ss-uploadcloud:before, .ss-uploadcloud.right:after {
  content: ''
}

.ss-upload:before, .ss-upload.right:after {
  content: ''
}

.ss-merge:before, .ss-merge.right:after {
  content: ''
}

.ss-fork:before, .ss-fork.right:after {
  content: ''
}

.ss-joinpath:before, .ss-joinpath.right:after {
  content: ''
}

.ss-splitpath:before, .ss-splitpath.right:after {
  content: ''
}

.ss-forkpath:before, .ss-forkpath.right:after {
  content: ''
}

.ss-refresh:before, .ss-refresh.right:after {
  content: '↻'
}

.ss-sync:before, .ss-sync.right:after {
  content: '🗘'
}

.ss-wifi:before, .ss-wifi.right:after {
  content: ''
}

.ss-connection:before, .ss-connection.right:after {
  content: ''
}

.ss-file:before, .ss-file.right:after {
  content: '📄'
}

.ss-files:before, .ss-files.right:after {
  content: ''
}

.ss-folder:before, .ss-folder.right:after {
  content: '📁'
}

.ss-anchor:before, .ss-anchor.right:after {
  content: '⚓'
}

.ss-print:before, .ss-print.right:after {
  content: '⎙'
}

.ss-list:before, .ss-list.right:after {
  content: ''
}

.ss-starlist:before, .ss-starlist.right:after {
  content: ''
}

.ss-layout:before, .ss-layout.right:after {
  content: ''
}

.ss-twoup:before, .ss-twoup.right:after {
  content: ''
}

.ss-threeup:before, .ss-threeup.right:after {
  content: ''
}

.ss-fourup:before, .ss-fourup.right:after {
  content: ''
}

.ss-action:before, .ss-action.right:after {
  content: ''
}

.ss-expand:before, .ss-expand.right:after {
  content: '⤢'
}

.ss-contract:before, .ss-contract.right:after {
  content: ''
}

.ss-lifepreserver:before, .ss-lifepreserver.right:after {
  content: ''
}

.ss-help:before, .ss-help.right:after {
  content: '❓'
}

.ss-info:before, .ss-info.right:after {
  content: 'ℹ'
}

.ss-alert:before, .ss-alert.right:after {
  content: '⚠'
}

.ss-plus:before, .ss-plus.right:after {
  content: '+'
}

.ss-hyphen:before, .ss-hyphen.right:after {
  content: '-'
}

.ss-check:before, .ss-check.right:after {
  content: '✓'
}

.ss-fish:before, .ss-fish.right:after {
  content: '🐟'
}

.ss-whale:before, .ss-whale.right:after {
  content: '🐋'
}

.ss-bird:before, .ss-bird.right:after {
  content: '🐦'
}

.ss-pawprint:before, .ss-pawprint.right:after {
  content: ''
}

.ss-bone:before, .ss-bone.right:after {
  content: ''
}

.ss-tree:before, .ss-tree.right:after {
  content: '🌲'
}

.ss-flower:before, .ss-flower.right:after {
  content: '⚘'
}

.ss-gem:before, .ss-gem.right:after {
  content: '💎'
}

.ss-mountains:before, .ss-mountains.right:after {
  content: '⛰'
}

.ss-settings:before, .ss-settings.right:after {
  content: '⚙'
}

.ss-dashboard:before, .ss-dashboard.right:after {
  content: ''
}

.ss-dial:before, .ss-dial.right:after {
  content: ''
}

.ss-notifications:before, .ss-notifications.right:after {
  content: '🔔'
}

.ss-toggles:before, .ss-toggles.right:after {
  content: '🎚'
}

.ss-switch:before, .ss-switch.right:after {
  content: ''
}

.ss-brightness:before, .ss-brightness.right:after {
  content: '🔆'
}

.ss-contrast:before, .ss-contrast.right:after {
  content: '◐'
}

.ss-flash:before, .ss-flash.right:after {
  content: '⌁'
}

.ss-magnet:before, .ss-magnet.right:after {
  content: ''
}

.ss-level:before, .ss-level.right:after {
  content: ''
}

.ss-toolbox:before, .ss-toolbox.right:after {
  content: ''
}

.ss-hammer:before, .ss-hammer.right:after {
  content: '🔨'
}

.ss-wrench:before, .ss-wrench.right:after {
  content: '🔧'
}

.ss-nut:before, .ss-nut.right:after {
  content: '🔩'
}

.ss-screw:before, .ss-screw.right:after {
  content: ''
}

.ss-clock:before, .ss-clock.right:after {
  content: '⏲'
}

.ss-stopwatch:before, .ss-stopwatch.right:after {
  content: '⏱'
}

.ss-alarmclock:before, .ss-alarmclock.right:after {
  content: '⏰'
}

.ss-counterclockwise:before, .ss-counterclockwise.right:after {
  content: '⥀'
}

.ss-calendar:before, .ss-calendar.right:after {
  content: '📅'
}

.ss-food:before, .ss-food.right:after {
  content: ''
}

.ss-egg:before, .ss-egg.right:after {
  content: ''
}

.ss-chickenleg:before, .ss-chickenleg.right:after {
  content: '🍗'
}

.ss-pizza:before, .ss-pizza.right:after {
  content: '🍕'
}

.ss-birthdaycake:before, .ss-birthdaycake.right:after {
  content: '🎂'
}

.ss-icecream:before, .ss-icecream.right:after {
  content: '🍨'
}

.ss-popsicle:before, .ss-popsicle.right:after {
  content: ''
}

.ss-coffee:before, .ss-coffee.right:after {
  content: ''
}

.ss-mug:before, .ss-mug.right:after {
  content: '☕'
}

.ss-beer:before, .ss-beer.right:after {
  content: '🍺'
}

.ss-bottle:before, .ss-bottle.right:after {
  content: ''
}

.ss-wineglass:before, .ss-wineglass.right:after {
  content: '🍷'
}

.ss-wine:before, .ss-wine.right:after {
  content: ''
}

.ss-cocktail:before, .ss-cocktail.right:after {
  content: '🍸'
}

.ss-cup:before, .ss-cup.right:after {
  content: ''
}

.ss-waterbottle:before, .ss-waterbottle.right:after {
  content: ''
}

.ss-utensils:before, .ss-utensils.right:after {
  content: '🍴'
}

.ss-measuringcup:before, .ss-measuringcup.right:after {
  content: ''
}

.ss-helmet:before, .ss-helmet.right:after {
  content: ''
}

.ss-graduationcap:before, .ss-graduationcap.right:after {
  content: '🎓'
}

.ss-glasses:before, .ss-glasses.right:after {
  content: '👓'
}

.ss-sunglasses:before, .ss-sunglasses.right:after {
  content: '🕶'
}

.ss-tie:before, .ss-tie.right:after {
  content: ''
}

.ss-bowtie:before, .ss-bowtie.right:after {
  content: ''
}

.ss-tshirt:before, .ss-tshirt.right:after {
  content: '👕'
}

.ss-jersey:before, .ss-jersey.right:after {
  content: ''
}

.ss-sock:before, .ss-sock.right:after {
  content: ''
}

.ss-handbag:before, .ss-handbag.right:after {
  content: '👜'
}

.ss-briefcase:before, .ss-briefcase.right:after {
  content: '💼'
}

.ss-messengerbag:before, .ss-messengerbag.right:after {
  content: ''
}

.ss-hanger:before, .ss-hanger.right:after {
  content: ''
}

.ss-safetypin:before, .ss-safetypin.right:after {
  content: ''
}

.ss-thermometer:before, .ss-thermometer.right:after {
  content: ''
}

.ss-cloud:before, .ss-cloud.right:after {
  content: '☁'
}

.ss-sun:before, .ss-sun.right:after {
  content: '☀'
}

.ss-partlycloudy:before, .ss-partlycloudy.right:after {
  content: '⛅'
}

.ss-rain:before, .ss-rain.right:after {
  content: '🌧'
}

.ss-thunderstorm:before, .ss-thunderstorm.right:after {
  content: '⛈'
}

.ss-snow:before, .ss-snow.right:after {
  content: '🌨'
}

.ss-umbrella:before, .ss-umbrella.right:after {
  content: '☂'
}

.ss-crescentmoon:before, .ss-crescentmoon.right:after {
  content: '🌙'
}

.ss-planet:before, .ss-planet.right:after {
  content: ''
}

.ss-recycle:before, .ss-recycle.right:after {
  content: '♲'
}

.ss-outlet:before, .ss-outlet.right:after {
  content: ''
}

.ss-car:before, .ss-car.right:after {
  content: '🚘'
}

.ss-train:before, .ss-train.right:after {
  content: '🚆'
}

.ss-bus:before, .ss-bus.right:after {
  content: '🚍'
}

.ss-trailer:before, .ss-trailer.right:after {
  content: ''
}

.ss-plane:before, .ss-plane.right:after {
  content: '✈'
}

.ss-boat:before, .ss-boat.right:after {
  content: '🚢'
}

.ss-sailboat:before, .ss-sailboat.right:after {
  content: '⛵'
}

.ss-boat:before, .ss-boat.right:after {
  content: '🚢'
}

.ss-schooner:before, .ss-schooner.right:after {
  content: ''
}

.ss-rocket:before, .ss-rocket.right:after {
  content: '🚀'
}

.ss-satellite:before, .ss-satellite.right:after {
  content: '🛰'
}

.ss-ufo:before, .ss-ufo.right:after {
  content: ''
}

.ss-helm:before, .ss-helm.right:after {
  content: '⎈'
}

.ss-fuel:before, .ss-fuel.right:after {
  content: '⛽'
}

.ss-firehydrant:before, .ss-firehydrant.right:after {
  content: ''
}

.ss-theatre:before, .ss-theatre.right:after {
  content: '🎭'
}

.ss-ticket:before, .ss-ticket.right:after {
  content: '🎫'
}

.ss-golf:before, .ss-golf.right:after {
  content: '⛳'
}

.ss-golfshot:before, .ss-golfshot.right:after {
  content: ''
}

.ss-basketball:before, .ss-basketball.right:after {
  content: '🏀'
}

.ss-hockeymask:before, .ss-hockeymask.right:after {
  content: ''
}

.ss-billiards:before, .ss-billiards.right:after {
  content: '🎱'
}

.ss-baseball:before, .ss-baseball.right:after {
  content: '⚾'
}

.ss-baseballfield:before, .ss-baseballfield.right:after {
  content: ''
}

.ss-tennis:before, .ss-tennis.right:after {
  content: '🎾'
}

.ss-tabletennis:before, .ss-tabletennis.right:after {
  content: ''
}

.ss-boxing:before, .ss-boxing.right:after {
  content: ''
}

.ss-bowling:before, .ss-bowling.right:after {
  content: ''
}

.ss-football:before, .ss-football.right:after {
  content: '🏈'
}

.ss-soccer:before, .ss-soccer.right:after {
  content: '⚽'
}

.ss-dumbbell:before, .ss-dumbbell.right:after {
  content: ''
}

.ss-fishhook:before, .ss-fishhook.right:after {
  content: ''
}

.ss-gameplan:before, .ss-gameplan.right:after {
  content: ''
}

.ss-hiker:before, .ss-hiker.right:after {
  content: ''
}

.ss-runner:before, .ss-runner.right:after {
  content: '🏃'
}

.ss-pedestrian:before, .ss-pedestrian.right:after {
  content: '🚶'
}

.ss-callbell:before, .ss-callbell.right:after {
  content: '🛎'
}

.ss-shower:before, .ss-shower.right:after {
  content: '🚿'
}

.ss-bandage:before, .ss-bandage.right:after {
  content: ''
}

.ss-pill:before, .ss-pill.right:after {
  content: '💊'
}

.ss-flask:before, .ss-flask.right:after {
  content: ''
}

.ss-testtube:before, .ss-testtube.right:after {
  content: ''
}

.ss-pulse:before, .ss-pulse.right:after {
  content: ''
}

.ss-up:before, .ss-up.right:after {
  content: '⬆'
}

.ss-right:before, .ss-right.right:after {
  content: '➡'
}

.ss-down:before, .ss-down.right:after {
  content: '⬇'
}

.ss-left:before, .ss-left.right:after {
  content: '⬅'
}

.ss-rightward:before, .ss-rightward.right:after {
  content: ''
}

.ss-leftward:before, .ss-leftward.right:after {
  content: ''
}

.ss-squiggle:before, .ss-squiggle.right:after {
  content: '⇝'
}

.ss-retweet:before, .ss-retweet.right:after {
  content: ''
}