
// Footer

.footer {
  background-image: linear-gradient(-135deg, #EEEEEE 0%, #FFFFFF 100%);
  padding: 45px 0;

  .footer-logo {
    height: 70px;
    width: auto;
    margin-top: 0.3em;
    margin-bottom: 15px;
  }

  p {
    margin: 0;
    color: $brand-primary;
    font-size: 14px;
  }

  .list-inline {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    margin-top: 0.8em;

    li {
      display: inline-block;
      border-right: 1px solid $brand-primary;
      padding-right: 10px;

      &:last-child {
        border-right: 0;
      }
    }
  }

  .regulatory {
    text-align: left;
  }

  .backup {
    text-align: left;

    a {
      font-size: 20px;
      margin-top: 0.3em;
    }
  }

  @media (min-width: $screen-sm) {

    .regulatory {
      text-align: right;
    }

    .backup {
      text-align: right;
    }

  }
}