
// Home

.hero {
  height: 100%;
  width: 100%;
  position: relative;
  background: black;
  background-size: cover!important;
  background-position: center!important;

  &.page-hero {
    height: 70%;

    .hero-caption {
      h1 {
        margin-top: 0;
      }
    }
  }

  .hero-caption {
    background-image: linear-gradient(-135deg, #1F2D4F 0%, rgba(31,44,79,0.55) 100%);
    position: absolute;
    bottom: 0; left: 0;
    padding: 50px 0;
    width: 100%;

    .caption-inner {
      float: right;
      padding: 0 40px 0 10px;
    }

    h1 {
      color: white;
      margin-bottom: 0;

      small {
        color: white;

        &:after {
          content: "";
          display: block;
          height: 1px;
          width: 53px;
          background: white;
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
    }
  }

  @media (min-width: $screen-sm) {
    height: 50%;

    .hero-caption {
      width: 50%;

      .caption-inner {
        width: 375px;
      }
    }
  }

  @media (min-width: $screen-md) {
    height: 100%;

    .hero-caption {
      .caption-inner {
        width: 485px;
      }
    }
  }

  @media (min-width: $screen-lg) {
    .hero-caption {
      .caption-inner {
        width: 585px;
      }
    }
  }
}

// Team Section

.team {

  .container-fluid {

    .col-sm-6 {
      padding-left: 0;
      padding-right: 0;

      &:last-child {
        border-right: 0;
      }
    }
  }

  .team-member {
    display: block;
    height: 430px;
    background-size: cover!important;
    background-position: center!important;
    position: relative;
    border-right: 1px solid white;
    margin-bottom: 111px;

    .name {
      position: absolute;
      bottom: -111px; left: 0; right: 0;
      background: $brand-primary;
      padding: 34px;
      transition: background 1s;

      img {
        height: 15px;
        margin-bottom: 5px;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 13.5px;
        letter-spacing: 0.15em;
        color: $brand-secondary;
        font-family: $font-family-serif;
        text-transform: uppercase;
      }
    }

    &:hover {
      .name {
        background: darken($brand-primary, 10%);
      }
    }

    &.elaine {
      .name {

        img {
          height: 20px;
          margin-bottom: 0;
        }
      }
    }
  }
}


// Project Section

.projects {
  .project {
    display: block;
    padding: 23px;
    border: 1px solid $brand-secondary;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;

    i {
      display: inline-block;
      vertical-align: middle;
      color: $brand-secondary;
      font-size: 18px;
      margin-right: 15px;
    }
  }

  .debt-projects {
    .project {
      background: $brand-primary;
      color: white;
      text-align: center;
      padding: 23px 10px;
      border-color: $brand-primary;
    }
  }
}


// Investment Extras

.investment-extras {
  background: $brand-primary;
  background-size: cover!important;
  background-position: center;
  color: white;


  .overlay {
    background-image: linear-gradient(-135deg, #1F2D4F 0%, rgba(31,44,79,0.48) 100%);
    padding: 68px 0;

    .extra {
      text-align: center;
      padding-bottom: 50px;

      &:last-child {
        padding-bottom: 0;
      }

      img {
        height: 60px;
        width: auto;
        display: block;
        margin: 0 auto 20px auto;
      }

      h2 {
        color: white;
        margin: 0 0 20px 0;
      }

      .readmore {
        color: white;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        opacity: 1;
        transition: opacity 1s;

        i {
          font-size: 12px;
          display: inline-block;
          vertical-align: middle;
        }

        &:hover {
          opacity: 0.5;
        }
      }

      @media (min-width: $screen-sm) {
        padding-bottom: 0;
      }
    }
  }
}

// Contact Page

.contacts {
  padding-top: $navbar-height;

  .contact {
    padding: 30px 15px;

    @media (min-width: $screen-sm) {
      padding: 34px;
      border-right: 1px solid rgba($brand-secondary, 0.5);
    }

    img {
      height: 15px;
      margin-bottom: 5px;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 13.5px;
      letter-spacing: 0.15em;
      color: $brand-secondary;
      font-family: $font-family-serif;
      text-transform: uppercase;
    }

    a {
      font-size: 14px;
      display: block;
      font-weight: 300;

      &:first-of-type {
        margin-top: 14px;
      }

      i {
        color: $brand-secondary;
        font-size: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
      }

    }

    &.elaine {
      img {
        height: 20px;
        margin-bottom: 0;
      }
    }

  }

}

.google-map {
  position: relative;

  #map {
    width: 100%;
    height: 620px;
    display: block;
    background: $gray-lighter;
  }

  #address {
    background-color: rgba(255,255,255, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    padding: 10px;
    text-align: center;
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
  }

  .btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

}

.map-marker {
  display: inline-block;
  background: $brand-primary;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}