
// Buttons

.btn {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 300;
  padding: 14px 22px;
  border-radius: 0;
  margin: 10px 0;
  white-space: normal;

  @media (min-width: $screen-sm) {
    white-space: nowrap;
  }

  &.btn-primary {
    a {
      color: white;
    }
  }
}