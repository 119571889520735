
// Glyphicons font path
$icon-font-path:        "../fonts/";


// Colors
$brand-primary:         #1F2D4F;
$brand-secondary:       #B1BDC8;


// Fonts
$font-family-sans-serif: "proxima-nova", serif;
$font-family-serif: "trajan-pro-3", serif;

// Typekit Fixes
.wf-loading {
    visibility: hidden;
}

.wf-active {
    visibility: visible;
}

.wf-inactive {
    visibility: hidden;
}

// Navbar

$navbar-height: 94px;